@import "./src/style/mixins";


.pp-popup-array {
  .arrow {
    position: fixed;
    top: 50%;
    width: 64px;
    height: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    z-index: 999;
    .image {
      width: 12px;
      height: 20px;
      cursor: pointer;
    }
    &.arrow-next {
      right: 40px;
    }
    &.arrow-prev {
      left: 40px;
      transform-origin: center;
      transform: translateY(-50%) rotate(180deg);
    }
    &.disabled {
      opacity: 0.5;
      .image {cursor: default;}
    }
  }
  .content-box {
    height: 100%;
    width: 100%;
  }
  .box {
    width: 80%;
    height: calc(95vh - 100px);
    //min-height: calc(90vh - 100px);
    top: 50px;
    margin: 0 auto;

    &.youtube, &.video,  {
      //height: 606px;
      width: 80%;
      max-width: 1078px;
      overflow: hidden;
      .content {
        background: transparent;
        width: 100%;
        video {
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
        }
        &:after {
          opacity: 0;
        }
        &.paused {
          &:after {
            content: "";
            width: 96px;
            height: 96px;
            cursor: pointer;
            opacity: 1;
            transition: 0.16s;
            background-size: cover;
            @include positionCC;
          }
        }
      }
    }
  }


  .content-image {
    //width: 100%;
    object-fit: cover;
    //height: 100%;
    background: transparent;
    display: flex;
    justify-content: center;
    img {
      border-radius: 12px;
      overflow: hidden;
      height: 100%;
      width: auto;
    }
  }
  .process {
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .content {
    background: transparent;
    height: 100%;
    width: 100%;
    .youtube {
      width: 100%;
      height: 100%;
    }
  }
  .process-block {
    width: 624px;
  }
}

@include break-point(tablet){
  .pp-popup-array {
    .arrow {
      &.arrow-next {
        right: 24px;
      }
      &.arrow-prev {
        left: 24px;
      }
    }
  }
}

@include break-point(mobile){
  .pp-popup-array {
    .close {
      //width: 40px;
      //height: 40px;
      //top: 2;
      //right: 24px;
    }
    .box {
      width: calc(100% - 32px);
      height: 100%;
      top: 0;
      & > .content {
        @include positionCC;
        height: auto;
          &:after {
            opacity: 0;
          }

          &.paused {
            &:after {
              content: "";
              width: 48px;
              height: 48px;
            }
          }
        .content-image {
          width: 100%;
          height: auto;
          img {
            width: 100%;
            height: auto;
          }
        }
        }
      &.process {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        & > .content {
          height: 100%;
        }
        .process-block {
          height: 100%;
          border-radius: 0;
          .image {
            width: 180px;
            height: 180px;
            img {
              height: 180px;
            }
          }
          .content {
            height: 100%;
            .text {
              height: 100%;
            }
          }
        }
      }

    }
    .arrow {
      position: fixed;
      top: unset;
      bottom: 40px;
      z-index: 999;
      transform: none;
      .image {
        width: 12px;
        height: 20px;
        cursor: pointer;
      }
      &.arrow-next {
        right: 24px;
      }
      &.arrow-prev {
        left: 24px;
        transform-origin: center;
        transform: translateY(0) rotate(180deg);
      }
    }
  }
}