@import "../../../style/mixins";

.title-block {

  .title {
    @include title-h1;
    display: block;
    margin: 24px auto 0;
    max-width: 740px;
  }

  .subtitle {
    @include Inter-standard-medium;
    color: var(--accent-color);
    &--accent-color {
      color: var(--accent-color);
    }
  }
  a {
    transition: 0.36s;
    &:hover {
      color: #CF307D;
    }
  }


  &.center, &.centered {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  &.white {
    .title {
      color: #fff;
    }
  }
}
.centered .title-block {
  margin: 0 auto;
  text-align: center;
}

@include break-point(mobile) {

  .title-block {

    .title {
      margin: 24px auto 0;
    }

    .subtitle {
      margin-top: 8px;
    }

    .btn-icon {
      margin-top: 16px;
    }
  }

}
