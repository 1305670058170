@import "./src/style/mixins";

.pp-popup-notification {
  .controls {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .btn {
      flex-shrink: 0;
      margin-right: 24px;
    }
    & > span {
      display: block;
      @include Inter-small;
      color: rgba(#000, 0.4);
      .link {
        opacity: 1;
        color: #358CEB;
        cursor: pointer;
        transition: color 0.36s;
        &:hover {
          color: #2A78CC;
        }
      }
    }
  }
  .box {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .content {
      background: #fff;
      border-radius: 12px;
      padding: 48px;
      width: 624px;
      height: auto;
      @include positionCC;
      //margin: 5% auto;
      .title-block {
        .title {
          margin-top: 8px;
          @include title-h2;
        }

        margin-bottom: 32px;
      }

      .input{
        &:not(:last-of-type){
          margin-bottom: 16px;
        }
      }
    }
  }

  .form {
  }
}

@include break-point(mobile){
  .pp-popup-notification {
    .box {
      .content {
        .title-block {
          .title {
            font-size: 34px;
            line-height: 38px;
          }
        }
        width: 100%;
        height: 100%;
        border-radius: 0;
        padding: 48px 16px;
      }
    }
    .controls {
      margin-top: 20px;
      flex-direction: column;
      //margin-top: 8px;
      .btn {
        width: 100%;
        margin: 0;
        margin-bottom: 21px;
      }
    }
  }
}