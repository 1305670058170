@font-face {
  font-family: 'Graphik RBC LC Bold';
  src: url('../assets/fonts/GraphikLC-Bold.woff2') format('woff2'),
  url('../assets/fonts/GraphikLC-Bold.ttf') format('truetype'),
  url('../assets/fonts/GraphikLC-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter Medium';
  src: url('../assets/fonts/Inter-Medium.woff2') format('woff2'),
  url('../assets/fonts/Inter-Medium.ttf') format('truetype'),
  url('../assets/fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-Regular.ttf') format('truetype'),
  url('../assets/fonts/Inter-Regular.woff') format('woff'),
  url('../assets/fonts/Inter-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}