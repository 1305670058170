@import "./src/style/mixins";


.thanks-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  .btn.dark.js-close {
    margin-top: 32px;
  }

  &__icon {
    width: 104px;
    height: 104px;
    margin-bottom: 24px;
  }
  &__text {
    @include title-h2;
  }
  &__btn {}
}

@include break-point(mobile){
  .thanks-block {
    height: 100%;
    justify-content: center;
    .btn.dark.js-close {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}