@import "./src/style/mixins";

.pp-section-prices {
  .list {
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    .price-block {
      width: calc((100% - 96px) / 3);
    }
  }
  .disclaimer {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 24px 32px;
    background: #fff;
    border-radius: 12px;
    .image {
      width: 48px;
      height: 48px;
      margin-right: 16px;
    }
    span {
      @include Inter-standard;
    }
  }
  .title-block {
    .title {
      text-align: center;
    }
    .subtitle {
      text-align: center;
    }
  }
}

@include break-point(mobile){
  .pp-section-prices {
    .list {
      flex-direction: column;
      .price-block {
        padding: 24px;
        width: 100%;
        .type {
          margin-bottom: 16px;
        }
        &:not(:last-of-type){
          margin-bottom: 16px;
        }
      }
    }
    .disclaimer {
      align-items: stretch;
      padding: 24px;
      .image {
        flex-shrink: 0;
        width: 32px;
        height: 32px;
      }
      br {
        display: none;
      }
    }
    .title-block {
      .title {
        text-align: left;
      }
      .subtitle {
        text-align: left;
      }
    }
  }
}