@import "./src/style/mixins";

.pp-section-process-of-work {
  background-color: #F9F8F7;
  .title-block {
    .title {
      margin-left: 0;
      max-width: 816px;
    }
  }
  .description {
    @include Inter-standard;
    display: block;
    max-width: 816px;
    margin-top: 16px;
  }
  .card-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 40px;
    @media (min-width: 1024px) {
      gap: 32px 48px;
    }
    .card {
      display: flex;
      flex-direction: column;
      gap: 12px;
      background-color: var(--white);
      padding: 24px;
      border-radius: 12px;
      flex: 1 1 100%;
      @media (min-width: 1024px) {
        flex-basis: calc((100% / 3) - 48px);
        padding: 32px;
      }
      &__step {
        @include title-h2;
        color: var(--accent-color);
      }
      &__description {
        @include Inter-standard;
      }
    }
  }
}