@import "../../style/mixins";

.pp-section-hero {
  width: 100%;
  padding: 96px 0 64px;
  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text, .video {
      width: 50%;
      flex-grow: 0;
      flex-shrink: 0;
    }
    .video {
      padding-left: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .title-block {
      margin-bottom: 16px;
    }
    p {
      display: block;
      margin-bottom: 40px;
      @include Inter-medium-regular;
      &.subtitle--accent-color {
        margin-top: 32px;
        margin-bottom: 16px;
        color: var(--accent-color)
      }
    }
  }
  .hint {
    margin-top: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      @include Inter-medium-regular;
    }
    .image {
      width: 16px;
      height: 24px;
      margin-right: 16px;
    }
  }
  .buttons {
    display: flex;
    gap: 16px;
  }
  .tgIcon {
    margin-right: 12px;
  }
}

@include break-point(mobile) {
  .pp-section-hero {
    padding: 37px 0 64px;
    .btn {
      width: 100%;
    }
    .content {
      flex-direction: column-reverse;
      .video, .text {
        width: 100%;
      }
      .video {
        padding-left: 0;
        //margin-bottom: 24px;
      }
    }
    .hint {
      display: none;
    }
    .container {
    }
    .buttons {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}
