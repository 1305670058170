@import "../../style/mixins";

header {
  width: 100%;
  .menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  .logo {
    padding: 32px;
    box-sizing: content-box;
    background: #fff;
    height: 32px;
    img {
      width: 176px;
      height: 32px;
    }
    width: auto;
  }
  nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    margin-right: 28px;
    span {
      flex-shrink: 0;
      @include Inter-standard;
      cursor: pointer;
      display: block;
      margin: 0 20px;
      transition: 0.36s;
      &:hover {
        color: #535353;
      }
    }
  }
  .btn {

  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

}

@include break-point(mobile) {
  header {
    height: 52px;
    width: 100%;
    .logo {
      background: transparent;
      padding: 0;
      height: 52px;
      img {
        height: 56px;
        width: 164px;
      }
    }
    .container {
      padding-left: 0;
    }
    .menu-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        @include Inter-standard;
      }
      svg {
        margin-left: 12px;
        width: 24px;
        height: 24px;
      }
    }
  }
}
