@import "../../../style/mixins";

.select {
  .select-visible {
    z-index: 2;
    border-radius: 8px;
    border: 2px solid rgba(#000, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9px 18px;
    cursor: pointer;
    justify-content: space-between;
    transition: 0.36s;
    .label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include Inter-small;
      color: #000;
      span {
        opacity: 0.4;
      }
      .value {
        opacity: 1;
        @include Inter-standard;
      }
    }
    svg {
      width: 24px;
      transition: 0.36s;
      height: 24px;
    }
    &:hover {
      background: #F9F8F7;
      border-color: #F9F8F7;
      svg {
        opacity: 0.4;
      }
    }
  }
  .select-hidden {
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
    width: 100%;
    background: #fff;
    z-index: 1;
    overflow: hidden;
    border-radius: 8px;
    position: absolute;
    top: 0;
    opacity: 0;
    transition: 0.16s;
    left: 0;
    li {
      list-style: none;
      @include Inter-standard;
      padding: 10px 20px;
      transition: background-color 0.16s;
      cursor: pointer;
      &:hover {
        background: #F9F8F7;
      }
      &.active {
        &:after {
          content: "";
          width: 24px;
          height: 24px;
          background: url("/assets/images/svg/select-check.svg") no-repeat;
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  .message {
    @include Inter-small;
    color: #F83D3B;
    margin-top: 4px;
    display: block;
    height: 0;
    opacity: 0;
    transition: 0.16s;
  }
  &.opened {
    .select-hidden {
      z-index: 3;
      opacity: 1;
    }
  }
}

.has-error .select {
  .select-visible {
    box-shadow: 0px 0px 0px 2px #EB5757;
  }

  .message {
    opacity: 1;
    height: 18px;
  }
}

@include break-point(mobile) {
  .select {

  }
}
