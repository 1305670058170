$primary-color: #00AAD2;
$gray-color: #EBEDF3;
$dark-color: #002C5E;

@mixin break-point($point) {
  @if $point == desktop-large {
    @media (min-width: 1920px) {
      @content;
    }
  }
  @else if $point == tablet {
    @media (max-width: 1024px) and (min-width: 768px){
      @content;
    }
  } @else if $point == mobile {
    @media (max-width: 1024px) {
      @content;
    }
  }
}

@mixin Inter($font-size, $line-height, $letter-spacing, $font-weight, $color) {
  @if $font-weight == "normal" {
    font-family: "Inter", "Arial", "sans-serif";
  } @else {
    font-family: "Inter Medium", "Arial", "sans-serif";
  }
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  color: $color;
}

@mixin Graphik($font-size, $line-height, $letter-spacing, $font-weight, $color) {
  font-family: "Graphik RBC LC Bold", "Arial", "sans-serif";
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  color: $color;
}

@mixin positionCC {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin positionX {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin positionY {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin title-h1 {
  @include Graphik(52px, 56px, -0.08px, 700, var(--black));
  @include break-point(mobile){
    font-size: 34px;
    line-height: 38px;
  }
}
@mixin title-h2 {
  @include Graphik(28px, 32px, -0.04px, 700, var(--black));
  @include break-point(mobile){
    font-size: 24px;
    line-height: 28px;
  }
}

@mixin Inter-small {
  @include Inter(14px, 18px, normal, normal, var(--black));

  @include break-point(mobile){
    font-size: 14px;
    line-height: 18px;
  }
}

@mixin Inter-standard {
  @include Inter(16px, 24px, normal, normal, var(--black));

  @include break-point(mobile){
    font-size: 14px;
    line-height: 18px;
  }
}

@mixin Inter-standard-medium {
  @include Inter(16px, 24px, normal, 500, var(--white));

  @include break-point(mobile){
    font-size: 14px;
    line-height: 18px;
  }
}

@mixin Inter-medium {
  @include Inter(18px, 28px, normal, 500, var(--white));

  @include break-point(mobile){
    font-size: 16px;
    line-height: 24px;
  }
}
@mixin Inter-medium-regular {
  @include Inter(18px, 28px, normal, normal, var(--black));

  @include break-point(mobile){
    font-size: 16px;
    line-height: 24px;
  }
}

@mixin Hint {
  display: block;
  @include Inter-standard;
  box-sizing: content-box;
  padding: 2px 14px;
  border: 2px solid var(--black);
  border-radius: 17px;
}

