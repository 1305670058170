.fadeImage {
  &.entering {
    animation: fade 1500ms forwards;
  }
  &.entered {}
  &.exiting {
    animation: fade 1500ms reverse;
  }
  &.exited {}
}

@keyframes fade {
  from { opacity: 0 }
  to { opacity: 1 }
}