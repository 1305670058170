@import "./src/style/mixins";

.textarea {
  &> div {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  position: relative;
  label {
    position: absolute;
    top: 19px;
    cursor: text;
    left: 20px;
    z-index: 1;
    @include Inter-standard;
    opacity: 0.4;
    transition: 0.3s;
  }
  textarea {
    transition: 0.36s;
    cursor: pointer;
    //width: calc(100% + 18px);
    width: 100%;
    height: 128px;
    border: 2px solid rgba(#000000, 0.1);
    padding: 19px 18px;
    border-radius: 8px;
    @include Inter-standard;
  }
  &:not(.focused):hover {
    textarea {
      border-color: #F9F8F7;
      background: #F9F8F7;
    }
  }
  &.focused {
    label {
      cursor: text;
      @include Inter-small;
      top: 9px;
      left: 18px;
      width: auto;
      height: auto;
    }
    textarea {
      cursor: text;
      padding: 24px 18px 19px;
    }
  }
  &.input:after {
   opacity: 0;
  }
  &.has-error {
    textarea {
      border-color: #F83D3B !important;
    }
    .error-message {
      margin: 0;
      margin-bottom: 8px;
      opacity: 1;
      @include Inter-small;
      color: #F83D3B;
    }
  }
}