@import "./src/style/mixins";

.brushes-block {
  margin-top: 32px;
  background: var(--black);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  overflow: hidden;
  .hint {
    @include Hint;
    @include Inter-standard-medium;
    color: var(--black);
    margin-bottom: 24px;
  }
  h4 {
    display: block;
    @include title-h2;
    color: var(--black);
    margin-bottom: 16px;
  }
  p {
    @include Inter-standard;
    display: block;
    margin-bottom: 32px;
  }
  .text {
    display: flex;
    padding: 48px;
    flex-direction: column;
    align-items: flex-start;
    .hint {
      color: #fff;
      border-color: #fff;
    }
    h4,
    p {
      color: #fff;
    }
    p {
      margin: 0;
    }
  }
  .cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 384px;
    height: 250px;
    .btn {
      @include positionCC;
      left: unset;
      right: 48px;
      transform: translateY(-50%);
      width: 100%;
    }
    .background {
      height: 100%;
      width: auto;

      //width: 100%;
      //height: auto;
      span,
      img {
        height: 100%;
        //width: 100%;
        //height: auto;
        object-fit: unset;
        //object-fit: cover;
      }
    }
  }
}

@include break-point(mobile) {
  .brushes-block {
    margin-top: 32px;
    flex-direction: column;
    .text {
      padding: 24px;
      .hint {
        color: #fff;
        border-color: #fff;
      }
      h4,
      p {
        color: #fff;
      }
      p {
        margin: 0;
      }
    }
    .cta {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: unset;
      height: auto;
      padding: 0 24px 24px;
      .btn {
        position: relative;
        right: unset;
        left: unset;
        top: unset;
        transform: none;
        width: 100%;
      }
      .background {
        height: auto;
        width: 60%;
        bottom: 0;
        position: absolute;
        right: 0;
        //width: 100%;
        //height: auto;
        span,
        img {
          display: block !important;
          //height: 100%;
          //width: 100%;
          //height: auto;
          object-fit: unset;
          //object-fit: cover;
        }
      }
    }
  }
}
