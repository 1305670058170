@import "./src/style/mixins";

.feature {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px;
  border-radius: 16px;
  .icon {
    width: 64px;
    height: 64px;
    margin-bottom: 24px;
  }
  p {
    @include Inter-medium;
    color: var(--black);
  }
}