@import "./src/style/mixins.scss";

.pp-popup-menu {
  .box {
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px;
    padding-top: 0;
    .logo {
      height: 56px;
      left: -11px;
      img {
        height:  56px;
        width: 154px;
      }
    }
    .controls {
      width: 100%;
      .btn {
        width: 100%;
        margin-top: 12px;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .links {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
      & > a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        cursor: pointer;
        border-radius: 12px;
        background: var(--black);
        .image {
          width: 48px;
          height: 48px;
          margin-right: 16px;
        }
        .text {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .title {
          @include Inter-standard-medium;
          color: #fff;
          margin-bottom: 2px;
        }
        .subtitle {
          @include Inter-small;
          color: #fff;
          opacity: 0.4;
        }
      }
    }
    .links {
      & > a {
        width: calc((100% - 8px) / 2);
        flex-direction: column;
        .image {
          width: 48px;
          height: 48px;
          margin-right: 0;
          margin-bottom: 12px;
        }
        .text {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .title {
          @include Inter-standard-medium;
          color: #fff;
          margin-bottom: 2px;
        }
        .subtitle {
          @include Inter-small;
          color: #fff;
          opacity: 0.4;
          text-align: center;
        }
      }
    }
    .menu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span {
        @include title-h2;
        display: flex;
        flex-direction: row;
        align-items: center;
        .type {
          @include Hint;
          @include Inter-standard-medium;
          color: var(--black);
          margin-left: 24px;
          padding: 2px 10px;
        }
        &:not(:last-of-type) {
          margin-bottom: 24px;
        }
      }
    }
  }
}