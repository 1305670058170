@import "./src/style/mixins";

.pp-popup-policy {
  .box {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .content {
      background: #fff;
      border-radius: 12px;
      padding: 48px;
      width: 624px;
      height: auto;
      margin: 5% auto;
      .title-block {
        .title {
          margin-top: 8px;
        }

        margin-bottom: 24px;
      }

      .policy {
        @include Inter-standard;

        p {
          display: block;
          margin-bottom: 16px;
        }

        ul {
          padding-left: 24px;

          li {
            list-style: none;
            margin-bottom: 16px;
            padding-left: 20px;

            &:before {
              content: "";
              width: 8px;
              height: 8px;
              background: var(--accent-color);
              border-radius: 50%;
              position: absolute;
              top: 12px;
              left: 0;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
}

@include break-point(mobile) {
  .pp-popup-policy {
    .box {
      background: #fff;
      .content {
        border-radius: 0;
        padding: 48px 16px;
        margin: unset;
        .title-block {
          .title {
            margin-top: 8px;
          }

          margin-bottom: 24px;
        }

        .policy {
          @include Inter-standard;

          p {
            display: block;
            margin-bottom: 16px;
          }

          ul {
            padding-left: 24px;

            li {
              list-style: none;
              margin-bottom: 16px;
              padding-left: 20px;

              &:before {
                content: "";
                width: 8px;
                height: 8px;
                background: var(--accent-color);
                border-radius: 50%;
                position: absolute;
                top: 12px;
                left: 0;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }
  }
}