@import "./src/style/mixins";

.pp-section-portfolio {
  background-color: #251B20;
  position: relative;
  .container {
    //background: url("/assets/images/desktop/portfolio/bg.png") no-repeat top center;
    //background-size: cover;
  }
  .background {
    position: absolute;
    width: 1620px;
    height: 961px;
    @include positionCC;
    img {
      width: 100%;
      height: 100%;
      object-fit: unset;
    }
  }
  .btn {
    margin: 66px auto 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .title-block {
    margin-bottom: 48px;
  }
  .list {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    gap: 48px;
    .example {
      width: calc((100% - 144px) / 4);
      background: #fff;
      border-radius: 8px;
      overflow: hidden;
      .image {
        overflow: hidden;
        cursor: pointer;
        max-height: 286px;
      }
      .text {
        padding: 24px;
        .title {
          @include Inter-medium;
          margin-bottom: 4px;
        }
        .subtitle {
          @include Inter-medium-regular;
        }
        .title, .subtitle {
          display: block;
          color: var(--black);
        }
      }
    }
  }
}

@include break-point(mobile){
  .pp-section-portfolio {
    .btn {
      margin-top: 32px;
      width: 100%;
      text-align: center;
    }
    .list {
      flex-direction: column;
      gap: 16px;
      .example {
        width: 100%;
        .image {
          max-height: unset;
        }
        &:not(:last-of-type){
          margin-bottom: 16px;
        }
      }
    }
  }
}