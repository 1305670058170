@import "../../style/mixins";

.pp-popup {
  //transform: translateX(16px);
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100vw;
  display: none;
  opacity: 0;
  transition: 0.4s transform, 0.4s opacity;
  z-index: 1000;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .box {
    z-index: 5;
  }

  .close {
    cursor: pointer;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    position: fixed;
    top: 24px;
    right: 24px;
    z-index: 10;
    transition: 0.26s;
    background: #fff;
    svg {
      @include positionCC;
      transform-origin: 50% 50%;
      width: 24px;
      height: 24px;
      transition: 0.36s;
    }

    &:hover {
      svg {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }

  &.open {
    opacity: 1;
  }

  &-modal {
    .overlay {
      background-color: rgba(0, 0, 0, 0.8);
      position: fixed;
    }

    .box {
      background-color: var(--primary-color);
      //top: 5%;
      //@include positionCC;
      //transform: translateX(-50%) translateY(-45%);
      transition: 0.5s transform;
    }

    &.open {
      opacity: 1;
      .box {
        //transform: translate(-50%, -50%);
      }
    }
  }

  &-youtube {

    .close {
      z-index: 10;
    }

    .box {
      height: 606px;
      width: 1078px;
      overflow: hidden;

      .youtube {
        width: 100%;
        height: 100%;
      }

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  &.black {
    .box {
      background-color: #000;
    }
  }
}

@include break-point(desktop-small) {
  .pp-popup {
    &-youtube {
      .box {
        height: 580px;
        width: 1032px;
      }
    }
  }
}

@include break-point(mobile) {
  .pp-popup {
      .close {
        background-color: var(--black);
        width: 56px;
        height: 56px;
        border-radius: 0;
        top: 0;
        right: 0;
        svg {
          fill: #fff;
          width: 24px;
          height: 24px;
        }
      }
  }
}
