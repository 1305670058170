@import "./src/style/mixins";

.text-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: 0.3s;
  &:not(.focused):hover {
    .block {
      border-color: #F9F8F7;
      background: #F9F8F7;
    }
  }
  .block {
    height: 64px;
    width: 100%;
    padding: 9px 18px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border: 2px solid rgba(#000000, 0.1);
    transition: 0.36s;
    label {
      cursor: pointer;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      padding: 18px 18px 20px;
      z-index: 2;
      @include Inter-standard;
      opacity: 0.4;
      transition: 0.3s;
      background: transparent;
    }
    input {
      height: 24px;
      width: 100%;
      z-index: 1;
      background: transparent;
      @include Inter-standard;
      opacity: 0;
      transition: 0.36s;
    }
  }

  .message {
    @include Inter-small;
    color: #F83D3B;
    margin-top: 4px;
    display: block;
    height: 0;
    opacity: 0;
    transition: 0.16s;
  }
  &.focused {
    .block {
      label {
        cursor: text;
        @include Inter-small;
        padding: 9px 18px;
        width: auto;
        height: auto;
      }
      input {
        opacity: 1;
      }
    }
  }



}
.has-error .text-input {
  .block {
    //box-shadow: 0px 0px 0px 2px #EB5757;
    border-color: #F83D3B;
  }
  .message {
    opacity: 1;
    height: 18px;
  }
}

@include break-point(mobile){
  .text-input {
    .block {
      height: 56px;
      padding: 9px 14px;
      label {

        padding: 19px 14px;
      }
    }
    input {
      //padding: 10px 8px;
    }
    &.focused {
      .block label {
        padding: 9px 14px;
        font-size: 12px;
      }
    }
  }
}