@import "./src/style/mixins";

.pp-section-about {
  background: #fff;
  .container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;

    & > .image {
      //margin-right: 48px;
      max-height: 680px;
    }
    & > div {
      width: calc(50% - 24px);
    }
    .content {
      width: calc(50% - 22px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .title-block {
        margin-bottom: 16px;
        .subtitle {
          color: var(--accent-color);
        }
      }
      p {
        @include Inter-medium-regular;
        font-weight: 500;
        font-family: "Inter Medium";
        color: var(--black);
      }
      .software {
        margin-top: 48px;
        display: flex;
        flex-direction: row;
        align-items: center;
        & > div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-right: 40px;
        }
        .image {
          width: 64px;
          height: 64px;
          margin-bottom: 16px;
        }
        span {
          @include Inter-medium;
          color: var(--black);
        }
      }
    }
  }
}

@include break-point(mobile){
  .pp-section-about {
    .container {
      flex-direction: column-reverse;
      & > .image {
        margin-top: 40px;
        //margin-right: 48px;
        max-height: 680px;
        span {
          display: block !important;
        }
      }
      & > div {
        width: auto;
      }
      .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .title-block {
          margin-bottom: 16px;
          .subtitle {
            color: var(--accent-color);
            margin-bottom: 24px;
          }
        }
        p {
          @include Inter-medium-regular;
          color: var(--black);
        }
        .software {
          display: none;
        }
      }
    }
  }
}