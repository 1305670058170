@import "./src/style/mixins";

.price-block {
  background: #fff;
  border-radius: 12px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-shrink: 0;
  .wrapper {
    width: 100%;
    flex-shrink: 0;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > .title {
      @include title-h2;
      color: var(--black);
      margin-bottom: 16px;
    }
  }
  .type {
    @include Hint;
    margin-bottom: 24px;
  }
  .btn {
    width: 100%;
  }
  .price-list {
    width: 100%;
    .price {
      &:first-child {
        .value {
          color: var(--accent-color);
        }
      }
      &:not(:last-of-type) {
        margin-bottom: 12px;
      }
      &:last-child {
        .price__item {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      &__item {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid rgba(36, 36, 36, 0.1);
        padding-bottom: 12px;
        gap: 0 12px;
      }
      .divider {
        width: 100%;
        height: 1px;
        background: var(--black);
      }
      .name {
        @include Inter-standard;
        width: 100%;
      }
      .value {
        @include Inter-standard-medium;
        color: var(--black);
        display: flex;
        align-items: flex-end;
      }
      .line {
        width: 100%;
        flex-grow: 1;
        &:after {
          content: "";
          width: calc(100% - 16px);
          height: 1px;
          opacity: 10%;
          background: var(--black);
          position: absolute;
          bottom: 6px;
          border-radius: 2px;
          left: 8px;
        }
      }
    }
  }

}