@import "../../../style/mixins";


.btn {
  cursor: pointer;
  transition: 0.36s;
  min-height: 60px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.dark {
    background: var(--black);
    @include Inter-standard-medium;
    padding: 12px 24px;
    &:hover {
      background: #313131;
    }
  }
  &.bright {
    background: var(--accent-color);
    @include Inter-medium;
    padding: 18px 32px;
    &:hover {
      background: #CF307D;
    }
  }
  &.telegram {
    background: #E1E9F2;
    @include Inter-medium;
    color: #358CEB;
    padding: 0 32px;
    &:hover {
      background: darken(#E1E9F2, 3%);
    }
  }
}

.loader {
  width: 24px;
  height: 24px;
  border: 3px dotted #FFF;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 20px; /* высота кнопки 60px / 2 (центр) и минус половина высоты loader-а */
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
  left: calc(50% - 12px);
  opacity: 0;
  transition: opacity 0.2s linear;

  &_loading {
    opacity: 1;
  }
}

.text {
  opacity: 1;
  transition: opacity 0.2s linear;

  &_hidden {
    opacity: 0;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@include break-point(mobile) {
  .btn {

  }
}
