@import "../../style/mixins.scss";

.error-message {
  opacity: 0;
  margin-top: 4px;
  cursor: default;
  transition: opacity 0.16s;
  display: flex;
  flex-direction: row;
  align-items: center;
  .icon {
    flex-shrink: 0;
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
  span {
    white-space: nowrap;
    display: block;
  }
}
