@import "./src/style/mixins";

.process-block {
  display: flex;
  flex-direction: column;
  padding: 32px;
  align-items: center;
  border-radius: 12px;
  overflow: hidden;
  .image {
    height: 180px;
    margin-bottom: 16px;
    width: auto;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span, p {
      display: block;
    }
    .index {
      @include Hint;
      margin-bottom: 16px;
    }
    .title {
      @include Inter-medium;
      color: var(--black);
      margin-bottom: 12px;
    }
    p {
      @include Inter-standard;
    }
  }
  .link {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    svg {
      fill: #358CEB;
      transition: 0.36s;
      margin-left: 8px;
      margin-top: 3px;
    }
    span {
      @include Inter-standard-medium;
      color: #358CEB;
      transition: 0.36s;
    }
    &:hover {
      span {
        color: #2A78CC;
      }
      svg {
        fill: #2A78CC;
      }
    }
  }
  &.extended {
    padding: 0;
    padding-top: 32px;
    .content {
      .index {
        margin-bottom: 24px;
      }
      .title {
        @include title-h2;
      }
      padding: 32px;
      padding-bottom: 48px;
      background: #fff;
    }
  }
}

@include break-point(mobile){
  .process-block {
    .image {
      height: 120px;
      img {
        height: 120px;
        width:auto;
      }
    }
  }
}