@import "./src/style/mixins";

.pp-section-features {
  .container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    .feature {
      width: calc((100% - 48px) / 3);
    }
  }
}

@include break-point(mobile){
  .pp-section-features {
    .swiper-container {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      .swiper-wrapper {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        .swiper-slide {
          flex-shrink: 0;
          .feature {
            width: auto;
          }
        }
      }
      .swiper-pagination {
        margin-top: 24px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
      .swiper-pagination-bullet {
        display: block;
        width: 8px;
        margin: 0 6px;
        height: 8px;
        flex-shrink: 0;
        border-radius: 50%;
        border: 2px solid var(--black);
        transition: 0.36s;
        &.swiper-pagination-bullet-active {
          background: var(--black);
        }
      }
    }
  }
}