@import "./src/style/mixins";

.pp-section-process {
  background: #fff;
  .list {
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    .process-block {
      width: calc((100% - 96px) / 3);
    }
  }
}

@include break-point(mobile){
  .pp-section-process {
    .list {
      flex-direction: column;
      .process-block {
        width: 100%;
        &:not(:last-of-type){
          margin-bottom: 16px;
        }
      }
    }
  }
}