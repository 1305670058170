@import "../../style/mixins";

footer {
  background-color: var(--black);
  text-align: center;
  padding: 32px 0;
  span {
    @include Inter-standard;
    color: rgba(#fff, 0.4);
  }
}


@include break-point(mobile) {
  footer {

  }
}
