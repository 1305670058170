@import "./src/style/mixins";

.pp-section-products {
  .title-block {
    margin-bottom: 48px;
  }
  .course-block {
    background: #fff;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    .image {
      height: auto;
      //width: auto;
      span {
        display: block !important;
        height: 100%;
        width: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .content {
      max-width: 52%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 48px;
    }
  }

  .hint {
    @include Hint;
    @include Inter-standard-medium;
    color: var(--black);
    margin-bottom: 24px;
  }
  h4 {
    display: block;
    @include title-h2;
    color: var(--black);
    margin-bottom: 16px;
  }
  p {
    @include Inter-standard;
    display: block;
    margin-bottom: 32px;
  }
}

@include break-point(mobile) {
  .pp-section-products {
    .course-block {
      border-radius: 12px;
      overflow: hidden;
      .content {
        max-width: unset;
        padding: 24px;
        .btn {
          width: 100%;
        }
      }
      flex-direction: column;
    }
  }
}
