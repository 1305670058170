@import "./src/style/mixins";

.file-input {
  width: 100%;

  input {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    opacity: 0;
    cursor: pointer;
  }
  .fileContainer {

  }
  .react-file-reader {
    height: 100%;
  }
  .react-file-reader-button {
    height: 100%;
  }
  .file-preview {
    border: 2px dashed rgba(#000, 0.1);
    border-radius: 8px;
    cursor: pointer;
    transition: 0.16s;
    &:hover {
      background: #F9F8F7;
      .info svg {
        //opacity: 0.4;
      }
    }
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .info {
      width: 100%;
      height: 100%;
      z-index: 2;
      //background: rgba(#fff, 0.5);
      display: flex;
      padding: 19px 16px 19px 20px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .size {
          @include Inter-small;
          opacity: 0.4;
        }
      }
      svg {
        //margin-right: 12px;
      }
      span {
        @include Inter-standard;
      }
    }
    svg {
      width: 24px;
      height: 24px;
      transition: 0.36s;
    }
    .preview {
      width: 100%;
      height: auto;
      position: absolute;
      z-index: 1;
      @include positionCC;
    }
    &.active {
      .info {
        padding: 9px 16px 9px 20px;
      }
    }
  }
  .loading{
    animation: 1s infinite rotate linear;
  }
  &.has-error {
    .error-message {
      opacity: 1;
    }
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.has-error > .file-input {
  .file-preview {
    border-color: #F83D3B;
    span {
      color: #F83D3B;
    }
  }
  .error-message {
    opacity: 1;
    @include Inter-small;
    color: #F83D3B;
  }
}

@include break-point(mobile){
  .file-input {
    //height: 45px;
    .file-preview {
      .info {
        padding: 19px 14px;
      }
    }
  }
}