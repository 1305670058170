@import "./src/style/mixins";

.pp-section-form {
  background: #fff;
  .title-block {
    margin-bottom: 48px;
  }
  .links {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    & > a {
      width: calc((100% - 16px) / 2);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      cursor: pointer;
      border-radius: 12px;
      background: var(--black);
      transition: background-color 0.36s;
      .image {
        width: 48px;
        height: 48px;
        margin-right: 16px;
      }
      .text {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .title {
        @include Inter-standard-medium;
        color: #fff;
        margin-bottom: 2px;
      }
      .subtitle {
        @include Inter-small;
        color: #fff;
        opacity: 0.4;
      }

      &:hover {
        background: #313131;
      }
    }
  }
  .form-title {
    margin: 32px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .line {
      height: 1px;
      border-radius: 1px;
      background: rgba(#000, 0.1);
      flex-grow: 1;
    }
    span {
      @include Inter-medium;
      color: var(--black);
      display: block;
      padding: 0 24px;
    }
  }
  form {
    .input {
      margin-bottom: 14px;
    }
  }

  .controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .btn {
      flex-shrink: 0;
      margin-right: 24px;
    }
    & > span {
      display: block;
      @include Inter-small;
      color: rgba(#000, 0.4);
      .link {
        opacity: 1;
        color: #358CEB;
        cursor: pointer;
        transition: color 0.36s;
        &:hover {
          color: #2A78CC;
        }
      }
    }
  }
}

@include break-point(mobile){
  .pp-section-form {

    .links {
      & > a {
        width: calc((100% - 8px) / 2);
        flex-direction: column;
        .image {
          width: 48px;
          height: 48px;
          margin-right: 0;
          margin-bottom: 12px;
        }
        .text {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .title {
          @include Inter-standard-medium;
          color: #fff;
          margin-bottom: 2px;
        }
        .subtitle {
          @include Inter-small;
          color: #fff;
          text-align: center;
          opacity: 0.4;
        }
      }
    }

    .controls {
      flex-direction: column;
      .btn {
        margin-right: 0;
        width: 100%;
        margin-bottom: 21px;
      }
      & > span {
        display: block;
        @include Inter-small;
        color: rgba(#000, 0.4);
        .link {
          opacity: 1;
          color: #358CEB;
          cursor: pointer;
          transition: color 0.36s;
          &:hover {
            color: #2A78CC;
          }
        }
      }
    }
  }
}